<template>
  <div>
    <div class="container-sm-lot pl-1 pr-1 ctx-rex mt-2">
      <div class="text-center">
        <h3 class="mb-0 text-primary">
          เข้าสู่ระบบหรือสมัครสมาชิก
        </h3>
      </div>
      <div class="cards ctx-card w-100 my-2">
        <div class="card-body">
          <div>
            <h3 class="text-primary mb-0">
              เข้าสู่ระบบ
            </h3>

            <div class="mt-1">
              <label
                for="username"
                class="text-primary"
              > ชื่อผู้ใช้ </label>

              <input
                id="username"
                v-model="email"
                type="text"
                class="form-custom-new w-100"
                placeholder="กรุณากรอก Username"
              >
            </div>

            <div class="mt-75">
              <label
                for="password"
                class="text-primary"
              > รหัสผ่าน </label>

              <input
                v-model="password"
                type="password"
                class="form-custom-new w-100"
                placeholder="กรุณากรอกรหัสผ่าน"
              >
            </div>

            <div class="mt-75 d-flex align-items-center">
              <b-form-checkbox
                id="rememberMe"
                v-model="rememberMe"
                value="true"
              />

              <label
                class="mt-n25"
                for="rememberMe"
                style="font-size: 20.3px !important"
              >จำฉันไว้ในระบบ</label>
            </div>

            <button
              class="btn-login-radius mt-2 mb-1 w-100"
              @click="login"
            >
              เข้าสู่ระบบ
            </button>

            <div class="text-center">
              <a
                :href="contact"
                class="cursor-pointer text-primary"
              >
                ลืมรหัสผ่าน ?
              </a>
            </div>

            <hr>
            <h3 class="text-primary mb-0">
              สมัครสมาชิก
            </h3>

            <button
              class="btn-golden-radius mt-2 mb-1 w-100"
              @click="registerLink()"
            >
              สมัครสมาชิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Home',
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      email: '',
      password: '',
      role: null,
      rememberMe: false,
      contact: null,
    }
  },
  created() {
    if (this.userData) {
      this.$router.push({ name: 'lottery' })
    }
  },
  mounted() {
    if (this.$route.query.tel && this.$route.query.password) {
      this.fwlogin(this.$route.query.tel, this.$route.query.password)
    }
    this.GetContact()
  },
  methods: {
    GetContact() {
      this.$http.get('/GetContact').then(res => {
        this.contact = res.data.linecontact
      })
    },
    registerLink() {
      if (this.$route.query.ref) {
        this.$router.push({
          name: 'register',
          query: { ref: this.$route.query.ref },
        })
      } else {
        this.$router.push({ name: 'register' })
      }
    },
    login() {
      if (this.rememberMe) {
        localStorage.setItem('username', this.username)
        localStorage.setItem('password', this.password)
      }
      // this.$refs.loginForm.validate().then(success => {
      // if (success) {
      // console.log(this.email)
      useJwt
        .login({
          email: this.email,
          password: this.password,
          agent_id: 1,
        })
        .then(response => {
          const userData = response.data
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem('MyCart', JSON.stringify([]))
          // console.log(userData)
          this.role = userData.role
          this.email = ''
          this.password = ''
          // this.hideModal()
          // this.getBalance()
          window.location.reload()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ยินดีต้อนรับ',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'คุณเข้าสู่ระบบสำเร็จแล้ว',
            },
          })
          this.$ability.update(userData.ability)

          if (this.role === 'admin') {
            window.location.href = '/lottery'
          }
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
      // }
      // })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ไม่สามารถเข้าสู้ระบบได้ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
